<template>	  
	  <div class="offerItem" id="offerItem">
	  	<Masthead :height="300" :noLetters="true" />	  	
	  	<section class="item">
		    <div class="container">
		    	<div class="row text-white">
		    		<nav aria-label="breadcrumb">
					  <ol class="breadcrumb">
					    <li class="breadcrumb-item"><router-link :to="$i18nRoute({ name: 'offer'})" href="#">{{ $t('offer') }}</router-link></li>
					    <li class="breadcrumb-item active" aria-current="page">{{ $t('offerItem') }}</li>
					  </ol>
					</nav>
		    	</div>
		      <div class="row pt-2 justify-content-md-center">   
		      	<div class="col-12 col-md-4">
		      		<img class="img-fluid" v-bind:src="screw.imgSrc" data-aos="flip-left"/> 
		      	</div> 		        
		      	<div class="col-12 col-md-6">
		      		<h3 class="text-center text-white pt-4">{{ $t(screw.title) }}</h3>
				    <p class="text-white" v-html="$t(screw.text)"></p>
		      	</div>
		      </div>
		    </div>
		 </section>
	  </div>
</template>
<script>
import Masthead from '@/components/Masthead.vue';
import offer from '../api/offer';
export default {
  	name: 'OfferItem',
  	components: {
	    Masthead
	}, 
	data: function () {
   		return {
   			screw:{
	   			title:'',
	   			text:'',
	   			id:0,
	   			imgSrc:''
   			}
   		}
   	},
   	metaInfo() {
  	return{
  		title: this._i18n.t('StalmatOffer') +' | '+ this._i18n.t(this.screw.title),
		meta: [
		      { 
		      	name: 'description', 
		      	content: this.screw.title 
		      },
		      { 
		            name: 'keywords', 
		            content: 'stalmat,śruby,śruby dwustronne,nakrętki,podkładki,sworznie' 
		          },
		          { 
		            name: 'author', 
		            content: 'https://www.itfrog.pl' 
		          },
		          { 
		            name: 'robots', 
		            content: 'index,follow' 
		          }
		    ]
	  	}
	  },
	created: function(){
		this.screw = offer[this.$route.params.id];
  	}
}
</script>